<ng-template #tmp>
    <fal [i]="icon" class="label-icon text-{{invalid ? 'danger' : 'primary'}} pl-2" *ngIf="icon && !compact"></fal>
    <ng-container *ngIf="label && !compact">
        <ng-container *ngIf="!read || readView !== 'simplified'; else simplifiedLabel">
            <label class="text-{{invalid ? 'danger' : 'primary'}}"
                   [class.wide]="wide"
                   [class.narrow]="narrow"
                   [class.d-inline-block]="read">
                {{label}}
            </label>
        </ng-container>
        <ng-template #simplifiedLabel>
            <strong [class.d-inline-block]="read">
                {{label}}
            </strong>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="!read; else readTmp">
        <ng-select #input
                   *ngIf="type == 'select'"
                   (click)="stopSelectPropagation($event)"
                   (ngModelChange)="onNgModelChange($event)"
                   (focus)="focus = true"
                   (blur)="onBlur()"
                   (close)="onClose($event)"
                   [ngModel]="_model"
                   [items]="selectItems"
                   [multiple]="multi"
                   [typeahead]="typeahead"
                   (search)="onSearch($event)"
                   [bindValue]="bindValue"
                   [bindLabel]="bindLabel"
                   [groupBy]="groupBy"
                   [selectableGroup]="selectableGroup"
                   [placeholder]="placeholder"
                   [required]="required"
                   [disabled]="disabled"
                   [compareWith]="compareWith"
                   [clearable]="clearable"
                   [searchFn]="searchFn"
                   [virtualScroll]="virtualScroll"
                   (clear)="clear.next()"
                   class="purified">
            <ng-template ng-option-tmp let-option="item">
                <app-select-option [option]="option"
                                   [searchTerm]="input.searchTerm"
                                   [bindValue]="bindValue"
                                   [bindLabel]="bindLabel"
                                   [bindCode]="bindCode"
                                   [bindSameAs]="bindSameAs">
                </app-select-option>
            </ng-template>
        </ng-select>
        <ng-container *ngIf="type == 'textarea'">
            <div #input
                 editable="true"
                 propValueAccessor="innerText"
                 unformattedPaste
                 class="textarea"
                 [ngModel]="_model"
                 (focus)="focus = true"
                 (blur)="onBlur()"
                 (ngModelChange)="onNgModelChange($event)"
                 (keydown)="onKeyDown($event)"
                 (paste)="onPaste($event)"
                 [attr.maxlength]="limit || null"
                 [attr.placeholder]="placeholder || null"
                 [style.minHeight]="rows ? rows * 1.5 + 'em' : null"
                 [disabled]="disabled"
                 [required]="required">
            </div>
        </ng-container>
        <input #input
               *ngIf="inputType && !type.includes('date') || type == 'input'"
               [(ngModel)]="_model"
               (ngModelChange)="onNgModelChange($event)"
               (focus)="focus = true"
               (blur)="onBlur()"
               [type]="inputType"
               [disabled]="disabled"
               [class.text-right]="align == 'right'"
               [class.text-center]="align == 'center'"
               [attr.maxlength]="limit || null"
               [attr.placeholder]="placeholder || null"
               [attr.min]="min"
               [attr.max]="max"
               [required]="required">
        <datepicker-select #input
                           *ngIf="type == 'date' || type == 'datetime'"
                           (dateChange)="onNgModelChange($event)"
                           [date]="_model"
                           [format]="type == 'datetime' ? 'date' : 'text'"
                           [hasTime]="type == 'datetime'"
                           [needsTimezone]="false"
                           [icon]="null"
                           [placeholder]="placeholder"
                           [clearable]="clearable"
                           controlClass="purified">
        </datepicker-select>
        <app-toggle #input
                    *ngIf="type == 'checkbox'"
                    (ngModelChange)="onNgModelChange($event)"
                    [ngModel]="_model"
                    [label]="compact ? placeholder : null"
                    [disabled]="disabled">
        </app-toggle>
    </ng-container>
    <ng-template #readTmp>
        <span class="value-caption">
            <ng-container *ngIf="type == 'checkbox'; else captionTmp">
                <fas *ngIf="_model === true" i="check" class="text-success"></fas>
                <fas *ngIf="_model === false" i="times" class="text-danger"></fas>
            </ng-container>
            <ng-template #captionTmp>
                <span class="preserve-newline">{{caption}}</span>
            </ng-template>
        </span>
    </ng-template>
    <div *ngIf="suffix" class="ml-2">{{suffix}}</div>
    <div *ngIf="error" class="text-danger ml-2" style="line-height: 1.2">{{error}}</div>
    <div *ngIf="!read && (limit || charCount)"
         [class]="'ml-2 text-' + (limit && _model?.length > limit ? 'danger' : limit && _model?.length == limit ? 'warning' : 'muted')">{{_model?.length || 0}}{{limit ? '/' + limit : ''}}</div>
    <ng-content></ng-content>
    <app-hint *ngIf="!read && hint">{{hint}}</app-hint>
</ng-template>

<ng-container *ngIf="!read || readView !== 'simplified'">
    <ng-container *ngTemplateOutlet="tmp"></ng-container>
</ng-container>
<ng-container *ngIf="read && readView === 'simplified'">
    <p class="m-0 flex-grow-1">
        <ng-container *ngTemplateOutlet="tmp"></ng-container>
    </p>
</ng-container>
